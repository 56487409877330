footer {
    align-items: center;
    justify-content: center;
    width: 90vw;
    margin: auto;
    padding: 70px 0 50px 0;
    text-align: center;
}

.footer-description {
    font-family: AktivGroteskRegular;
}

.social-link img{
    max-width: 35px;
    margin: 10px;
}

.social-link {
    margin: 0 10px 0 10px;
}

@media (max-width: 780px) {
    .social-link img {
        max-width: 20px;
    }
}
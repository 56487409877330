
section#gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    width: 90vw;
    margin: auto;
    padding-bottom: 100px;
    font-size: 1vw;   
}

.card {
    position: relative;
    overflow: hidden;
}

.card-image img{
    width: 100%;
    transition: all .5s ease;
}

.card:hover .card-image img {
    filter: brightness(50%);
}

.card-description-absolute {
    width: 100%;
    height: 40%;
    position: absolute;
    bottom: -25px;
    padding-bottom: 10px;
    opacity: 0;
    color: aliceblue;
    background-color: black;
    transition: bottom .5s ease, opacity .5s ease;
}

.card:hover .card-description-absolute {
    bottom: 0px;
    opacity: 1;
}

.card-description {
    padding: 0px 20px 0px 30px;
    font-family: AktivGroteskMedium;
    font-size: 1.5em;
}

.card-description-link {
    line-height: 0;
}

.card-link {
    color: aliceblue;
    text-decoration: none;
    padding-bottom: 3px;
    border-bottom: 2px solid black;
    text-align: center;
    border-bottom: 1px solid aliceblue;
}


@media (max-width: 780px) {
    section#gallery {
        display: grid;
        grid-template-columns: 1fr;
        font-size: 1em;
    }

    .card-description-absolute {
        width: 100%;
        height: 45%;
        position: absolute;
        line-height: 3vw;
        bottom: 0;
        opacity: 1;
        color: aliceblue;
        background-color: black;
    }

    .card-description h3 {
        line-height: 1em;
        font-size: 3.5vw;
    }

    .card-description-link {
        line-height: 0;
        font-size: 3vw;
    }
}